<template>
    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Seznam odeslaných emailů</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%;margin-top: 25px">
                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                    </div>
                </div>
                <div class="col-lg-2">
                    <label>Odeslané od</label>
                    <v-date-picker mode="dateTime" v-model="fromdate" class="flex-grow" is24hr :max-date="todate ? todate : Date.now()">
                        <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="fromdate" class="form-control" autocomplete="off">
                    </v-date-picker>
                </div>
                <div class="col-lg-2">
                    <label>Odeslané do</label>
                    <v-date-picker mode="dateTime" v-model="todate" class="flex-grow" is24hr :min-date="fromdate" :max-date="Date.now()">
                        <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="todate" class="form-control" autocomplete="off">
                    </v-date-picker>
                </div>
                <div class="col-lg-4">
                    <button type="button" class="btn btn-default dropdown-toggle" style="margin-top: 25px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filtrovat <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li><label><input type="checkbox" v-model="showByType.general"> Všeobecná zpráva</label></li>
                        <li><label><input type="checkbox" v-model="showByType.stk"> Upozornění na STK</label></li>
                        <li><label><input type="checkbox" v-model="showByType.issue"> Upozornění na úkon</label></li>
                    </ul>
                </div>
            </div>
            <br>
            <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                <table v-if="getEmails.length" class="table table-hover">
                    <thead>
                    <tr>
                        <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <template v-for="email in getEmails">
                            <tr v-bind:key="email.emailid">
                                <td>{{email.sent_str}}</td>
                                <td>{{email.to}}</td>
                                <td>{{email.email_subject}}</td>
                                <td @click="toggleCollapse(email.emailid)" style="cursor:pointer"><i class='fa fa-search'></i> {{collapsedRow == email.emailid ? "Skrýt" : "Zobrazit"}}</td>
                            </tr>
                            <tr v-if="collapsedRow == email.emailid" v-bind:key="'collapse'+email.emailid">
                                <td colspan="4"><p class="email-content">{{email.email_content}}</p></td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div v-else class="callout callout-success">
                    <p><i class="icon fa fa-info"></i> Pro zadané parametry nebyly nalezeny žádné výsledky.</p>                          
                </div>
            </div>
        </div>
        <div v-if="!loaded" class="overlay">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
    </div>
</template>

<script>

const name = 'EmailHistory';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {

        const threshold = parseInt(this.$route.query.threshold) || 30;

        var d = new Date();
        d.setHours(23,59,0,0);
        var todate = d.getTime();
        
        d = new Date();
        d.setDate(d.getDate()-threshold);
        d.setHours(0,0,0,0);
        var fromdate = d.getTime();

        return {
            threshold,
            loaded: false,
            filter: '',
            showByType: {
                general: false,
                stk: false,
                issue: false
            },
            collapsedRow: 0,
            fromdate,
            todate,
            headColumns: [
                { name: "Datum odeslání" },
                { name: "Příjemce" },
                { name: "Předmět" }
            ]
        }
    },
    computed: {
        ...mapGetters(["getAllPages", 'user', 'group', 'allEmails']),
        getEmails() {

            var allEmails = this.allEmails;

            if(!allEmails) {
                return []
            }

            //filter by the time, search field and email types
            return allEmails.filter(item => {
                return ( item.sent*1000 >= this.fromdate && item.sent*1000 <= this.todate
                && (this.$helpers.filterCheck(this.filter, item.to)
                        || this.$helpers.filterCheck(this.filter, item.email_subject)
                    )
                && (!this.showByType.general && !this.showByType.stk && !this.showByType.issue)
                || (this.showByType.general && this.$helpers.filterCheck("car", item.sourceid))
                || (this.showByType.stk && this.$helpers.filterCheck("stk", item.sourceid))
                || (this.showByType.issue && this.$helpers.filterCheck("issue", item.sourceid))
                );
            });
        }
    },
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "fetchEmails"]),
        toggleCollapse(id) {
            if(this.collapsedRow == id) {
                this.collapsedRow = 0;
            } else {
                this.collapsedRow = id;
            }
        },
        getTime(epochTime) {
            const dt = new Date(epochTime * 1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            const hrs = dt.getHours();
            let min = dt.getMinutes();

            if (min < 10)
            {
                min = '0' + min.toString();
            }

            return `${day}.${mth}.${yrs}  ${hrs}:${min}`;
        },
    },
    created() {
        this.fetchEmails().then( () => {
            this.loaded = true;

            this.allEmails.forEach((email) => {
                email.sent_str = this.getTime(email.sent);
                email.email_content = email.email_content.replace(/<\/?[^>]+>/ig, "");
            });
        });

        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);
    }  
}

</script>

<style scoped>
    ul.dropdown-menu li {
        margin-left: 8px;
        color: #000;
    }
    .email-content {
        padding: 10px 10px;
        margin: 0 0 10px;
        font-size: 12.5px;
        border-left: 5px solid #eee;
    }
</style>